<template>
    <v-card>
        <v-card-title class="white--text primary">Collected Report Data</v-card-title>

        <v-row class="py-2 mx-2">
            <v-col cols="12">
                <h3>Total: {{ campaignVerifications.percentage }}%</h3>
                <v-treeview
                    hoverable
                    :items="treeViewData"
                ></v-treeview>
            </v-col>
        </v-row>
  </v-card>
</template>

<script>
    // Mixins
    import roundNumber from '@/mixins/helpers/roundNumber'

    // External libraries
    import moment from 'moment'

    export default {
        mixins: [
            roundNumber,
        ],

        props: {
            campaignId: {
                required: true,
            },

            campaignVerifications: {
                required: true,
            }
        },

        data() {
            return {
                items: [
                    
                ],
            }
        },

        computed: {
            treeViewData() {
                let data = []

                this.campaignVerifications.bursts.forEach(burst => {
                    let burstData = {}

                    burstData.id = burst.campaignBurstId
                    burstData.name = burst.name+ ': ' + this.roundMaxDecimals(burst.percentage, 2) + '%'

                    burstData.children = []

                    burst.mediaOwners.forEach(mediaOwner => { 
                        let mediaOwnerData = {}
                        mediaOwnerData.children = []

                        mediaOwnerData.id = mediaOwner.mediaOwnerId
                        mediaOwnerData.name = mediaOwner.name + ': ' + mediaOwner.percentage + '%'

                        
                        mediaOwner.screens.forEach(screen => { 
                            let screenData = {}
                            
                            screenData.id = screen.screenId
                            screenData.name = screen.name + ': ' + screen.percentage + '%'
                            
                            
                            screenData.children = []

                            screen.dates.forEach((date, index) => {
                                let dateData = {}

                                dateData.id = index
                                dateData.name = moment(date.date).format("DD MMMM YYYY") + ': ' + ((date.status == 1) ? '✔️' : '❓')

                                screenData.children.push(dateData)
                            })


                            mediaOwnerData.children.push(screenData)
                        })

                        burstData.children.push(mediaOwnerData)
                    })

                    data.push(burstData)
                })

                return data
            }
        },
    }
</script>

<style lang="scss" scoped>

</style>